import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import { motion } from 'framer-motion';
import { FiUser, FiMail, FiEdit2, FiMessageCircle } from 'react-icons/fi';

const ContactContainer = styled.section`
  background: linear-gradient(135deg, #cccccc, #d9e4ff);
  padding: 80px 20px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 60px 20px;
  }

  @media (max-width: 480px) {
    padding: 40px 20px;
  }
`;

const DecorativeCircle = styled.div`
  position: absolute;
  top: -10%;
  right: -10%;
  width: 30%;
  height: 30%;
  max-width: 250px;
  max-height: 250px;
  background-color: rgba(0, 32, 96, 0.1);
  border-radius: 50%;
  z-index: 0;
`;

const DecorativeCircleLeft = styled.div`
  position: absolute;
  bottom: -10%;
  left: -10%;
  width: 30%;
  height: 30%;
  max-width: 250px;
  max-height: 250px;
  background-color: rgba(0, 32, 96, 0.1);
  border-radius: 50%;
  z-index: 0;
`;

const ContactTitle = styled.h3`
  text-align: center;
  font-size: 36px;
  color: #002060;
  margin-bottom: 20px;
  font-weight: 700;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 15px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
`;

const ContactSubtitle = styled.p`
  text-align: center;
  font-size: 20px;
  color: #333333;
  margin-bottom: 60px;
  font-weight: 400;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 40px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    margin-bottom: 30px;
  }
`;

const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

const FormGroup = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

const IconWrapper = styled.span`
  position: absolute;
  top: 18px;
  left: 15px;
  color: #666666;
`;

const FloatingLabel = styled.label`
  position: absolute;
  top: ${({ isFocused }) => (isFocused ? '5px' : '18px')};
  left: 45px;
  font-size: ${({ isFocused }) => (isFocused ? '12px' : '16px')};
  color: ${({ isFocused }) => (isFocused ? '#002060' : '#666666')};
  pointer-events: none;
  transition: all 0.2s ease-out;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px 15px 15px 45px;
  border: 1px solid ${({ isError }) => (isError ? 'red' : '#cccccc')};
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background-color: #ffffff;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: #002060;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 15px 15px 15px 45px;
  border: 1px solid ${({ isError }) => (isError ? 'red' : '#cccccc')};
  border-radius: 5px;
  font-size: 16px;
  height: 150px;
  resize: vertical;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background-color: #ffffff;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: #002060;
  }
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 14px;
  position: absolute;
  bottom: -20px;
  left: 45px;
`;

const SubmitButton = styled(motion.button)`
  background-color: #002060;
  color: #ffffff;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  font-size: 18px;
  cursor: pointer;
  display: block;
  margin: 40px auto 0 auto;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background-color 0.3s, box-shadow 0.3s;
  position: relative;
  z-index: 1;

  &:hover {
    background-color: #003399;
    box-shadow: 0 8px 15px rgba(0, 51, 153, 0.2);
  }

  @media (max-width: 480px) {
    font-size: 16px;
    padding: 12px 25px;
  }
`;

const Message = styled.p`
  text-align: center;
  font-size: 16px;
  color: ${({ error }) => (error ? 'red' : 'green')};
  margin-top: 20px;
  position: relative;
  z-index: 1;
`;

function ContactForm() {
  const form = useRef();
  const [statusMessage, setStatusMessage] = useState('');
  const [error, setError] = useState(false);

  // State for input focus and validation
  const [focusStates, setFocusStates] = useState({
    name: false,
    email: false,
    subject: false,
    message: false,
  });

  const [errors, setErrors] = useState({});

  const handleFocus = (field) => {
    setFocusStates({ ...focusStates, [field]: true });
  };

  const handleBlur = (field, value) => {
    setFocusStates({ ...focusStates, [field]: value !== '' });
  };

  const validateForm = () => {
    const newErrors = {};
    const formElements = form.current.elements;

    if (!formElements.user_name.value.trim()) {
      newErrors.user_name = 'Please enter your name';
    }

    if (!formElements.user_email.value.trim()) {
      newErrors.user_email = 'Please enter your email';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formElements.user_email.value)
    ) {
      newErrors.user_email = 'Please enter a valid email address';
    }

    if (!formElements.subject.value.trim()) {
      newErrors.subject = 'Please enter a subject';
    }

    if (!formElements.message.value.trim()) {
      newErrors.message = 'Please enter your message';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setStatusMessage('Please fix the errors in the form.');
      setError(true);
      return;
    }

    emailjs
      .sendForm(
        'service_7t17uqt', 
        'template_68392eu', 
        form.current,
        'GqMHmB3cjp0zue_rw' 
      )
      .then(
        (result) => {
          console.log(result.text);
          setStatusMessage('Your message has been sent successfully!');
          setError(false);
          form.current.reset();
          setFocusStates({
            name: false,
            email: false,
            subject: false,
            message: false,
          });
          setErrors({});
        },
        (error) => {
          console.log(error.text);
          setStatusMessage(
            'An error occurred while sending your message. Please try again later.'
          );
          setError(true);
        }
      );
  };

  return (
    <ContactContainer id="contact">
      <DecorativeCircle />
      <DecorativeCircleLeft />
      <ContactTitle>Ready to Elevate Your Test Prep?</ContactTitle>
      <ContactSubtitle>
        Contact us today and get access to premium test questions at unbeatable rates.
      </ContactSubtitle>
      <Form ref={form} onSubmit={handleSubmit} noValidate>
        {/* Name Field */}
        <FormGroup>
          <IconWrapper>
            <FiUser size={20} />
          </IconWrapper>
          <FloatingLabel isFocused={focusStates.name}>
            Name
          </FloatingLabel>
          <Input
            type="text"
            name="user_name"
            onFocus={() => handleFocus('name')}
            onBlur={(e) => handleBlur('name', e.target.value)}
            isError={errors.user_name}
          />
          {errors.user_name && (
            <ErrorMessage>{errors.user_name}</ErrorMessage>
          )}
        </FormGroup>

        {/* Email Field */}
        <FormGroup>
          <IconWrapper>
            <FiMail size={20} />
          </IconWrapper>
          <FloatingLabel isFocused={focusStates.email}>
            Email
          </FloatingLabel>
          <Input
            type="email"
            name="user_email"
            onFocus={() => handleFocus('email')}
            onBlur={(e) => handleBlur('email', e.target.value)}
            isError={errors.user_email}
          />
          {errors.user_email && (
            <ErrorMessage>{errors.user_email}</ErrorMessage>
          )}
        </FormGroup>

        {/* Subject Field */}
        <FormGroup>
          <IconWrapper>
            <FiEdit2 size={20} />
          </IconWrapper>
          <FloatingLabel isFocused={focusStates.subject}>
            Subject
          </FloatingLabel>
          <Input
            type="text"
            name="subject"
            onFocus={() => handleFocus('subject')}
            onBlur={(e) => handleBlur('subject', e.target.value)}
            isError={errors.subject}
          />
          {errors.subject && (
            <ErrorMessage>{errors.subject}</ErrorMessage>
          )}
        </FormGroup>

        {/* Message Field */}
        <FormGroup>
          <IconWrapper>
            <FiMessageCircle size={20} />
          </IconWrapper>
          <FloatingLabel isFocused={focusStates.message}>
            Message
          </FloatingLabel>
          <TextArea
            name="message"
            onFocus={() => handleFocus('message')}
            onBlur={(e) => handleBlur('message', e.target.value)}
            isError={errors.message}
          ></TextArea>
          {errors.message && (
            <ErrorMessage>{errors.message}</ErrorMessage>
          )}
        </FormGroup>

        <SubmitButton
          type="submit"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Contact Us Now
        </SubmitButton>
        {statusMessage && <Message error={error}>{statusMessage}</Message>}
      </Form>
    </ContactContainer>
  );
}

export default ContactForm;
