import React from 'react';
import styled from 'styled-components';
import { FaPenFancy, FaBook, FaHandshake, FaLightbulb } from 'react-icons/fa';
import { motion } from 'framer-motion';

// Styled Components
const FeaturesContainer = styled.section`
  background-color: #ffffff;
  padding: 80px 20px;

  @media (max-width: 768px) {
    padding: 60px 20px;
  }

  @media (max-width: 480px) {
    padding: 40px 20px;
  }
`;

const FeaturesTitle = styled.h3`
  text-align: center;
  font-size: 36px;
  color: #002060;
  margin-bottom: 60px;
  font-weight: 700;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 50px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    margin-bottom: 40px;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const FeatureItem = styled(motion.div)`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;

  /* Flex settings to align content */
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);

    &:before {
      transform: scale(1.5);
      opacity: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle at center, rgba(0, 32, 96, 0.1), transparent);
    opacity: 0;
    transition: opacity 0.5s, transform 0.5s;
  }
`;

const FeatureIcon = styled.div`
  font-size: 48px;
  color: #002060;
  margin-bottom: 20px;
`;

const FeatureTitle = styled.h4`
  font-size: 24px;
  color: #002060;
  margin-bottom: 15px;
  font-weight: 600;
  min-height: 60px; /* Set a minimum height to align titles */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  /* Adjust line height for consistency */
  line-height: 1.2;
`;

const FeatureDescription = styled.p`
  color: #666666;
  font-size: 16px;
  line-height: 1.6;
  flex-grow: 1; /* Allows descriptions to take up remaining space if needed */
`;

function Features() {
  const features = [
    {
      icon: <FaPenFancy />,
      title: 'Bespoke Question Crafting',
      description:
        'Our experts meticulously create each question, ensuring unparalleled quality and originality tailored to your curriculum.',
    },
    {
      icon: <FaBook />,
      title: 'Comprehensive Test Coverage',
      description:
        'Access an extensive library covering a wide range of US standardized tests, all in one place.',
    },
    {
      icon: <FaHandshake />,
      title: 'Seamless Direct Collaboration',
      description:
        'Partner directly with our content creators to customize materials that meet your unique needs.',
    },
    {
      icon: <FaLightbulb />,
      title: 'Innovative Solutions ',
      description:
        'Leverage our team’s deep insights and experience to stay ahead in educational content delivery.',
    },
  ];

  return (
    <FeaturesContainer id="features">
      <FeaturesTitle>Experience the CalC Advantage</FeaturesTitle>
      <FeaturesGrid>
        {features.map((feature, index) => (
          <FeatureItem
            key={index}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2 }}
          >
            <FeatureIcon>{feature.icon}</FeatureIcon>
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureDescription>{feature.description}</FeatureDescription>
          </FeatureItem>
        ))}
      </FeaturesGrid>
    </FeaturesContainer>
  );
}

export default Features;
