import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Styled Components
const PortfolioContainer = styled.section`
background: linear-gradient(135deg, #cccccc, #d9e4ff);
padding: 80px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;



const PortfolioTitle = styled.h3`
  text-align: center;
  font-size: 36px;
  color: #333333;
  margin-bottom: 60px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 40px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    margin-bottom: 30px;
  }
`;

const ExamsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Maintain 3 columns on larger screens */
  gap: 40px;
  max-width: 1200px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
    gap: 30px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; /* 1 column on small screens */
    gap: 20px;
  }
`;

const ExamCard = styled(motion.div)`
  background-color: #f5f7fa;
  padding: 30px;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  /* Center content inside the card */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  @media (max-width: 480px) {
    padding: 20px;
  }
`;

const ExamName = styled.h4`
  font-size: 20px;
  color: #333333;
  margin: 0;
  font-weight: 600;

  /* Allow text to wrap and break words if necessary */
  word-break: break-word;
  white-space: normal;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

function ExamPortfolio() {
  const exams = [
    'SAT',
    'ACT',
    'GMAT',
    'GRE',
    'JEE Advanced',
    'CAT',
  ];

  return (
    <PortfolioContainer id="examportfolio">
     
      <PortfolioTitle>Exam Portfolio</PortfolioTitle>
      <ExamsGrid>
        {exams.map((exam, index) => (
          <ExamCard
            key={index}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, delay: index * 0.05 }}
          >
            <ExamName>{exam}</ExamName>
          </ExamCard>
        ))}
      </ExamsGrid>
    </PortfolioContainer>
  );
}

export default ExamPortfolio;
